/* eslint-disable react/no-unescaped-entities */
import { Heading, Paragraph } from '../privacy-policy';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { seoConfiguration } from '@/utils/seo-config';

import './terms-of-service.scss';

const TermsOfServicePage = () => {
  return (
    <Layout {...content.seo}>
      <div className="terms-of-service-page">
        <PageHeader
          textPrimary={{
            text: 'Akuity Master Subscription Agreement',
            size: 45
          }}
          textDescription={{
            text: 'Last Updated: August 1st, 2022'
          }}
        />

        <section>
          <Paragraph>
            <b>THIS DOCUMENT IS A LEGAL CONTRACT. PLEASE READ IT CAREFULLY.</b>{' '}
            This Master Subscription Agreement (“<b>Agreement</b>”) is an
            agreement between you as our customer (“<b>Customer</b>”, or “
            <b>you</b>”) and governs your access to and use of the Akuity
            software services (collectively, the “<b>Service</b>”) provided by
            Akuity, Inc. (“<b>Akuity</b>”, or “<b>we</b>”). Use of and access to
            the Service is conditioned upon your compliance with this Agreement
            and all applicable local, state, national, and international laws,
            rules and regulations. Your use of the Service indicates your
            acceptance of this Agreement, and that you understand and agree to
            be bound by this Agreement. We may modify this Agreement (including
            any policies we reference) at any time by posting a revised version
            on the Akuity website or by otherwise notifying you in accordance
            with Section 11.6. The modified Agreement will become effective upon
            posting or, if we notify you by email, as stated in the email
            message. By continuing to use the Service after the effective date
            of any modifications to this Agreement, you agree to be bound by the
            modified Agreement. We last modified this Agreement on the date
            listed at the beginning of this Agreement.
          </Paragraph>
        </section>

        <section>
          <Heading>1. Free Trial</Heading>
          <Paragraph>
            You can receive a free trial to our Service for the trial period of
            fourteen (14) days, or such longer period at the exclusive
            discretion of Akuity (the “<b>Trial Period</b>”). You may be
            required to agree to additional terms and conditions, which will be
            presented to you prior to your registration for the free trial. If
            you choose to subscribe to the Service at or before the end of the
            Trial Period, we will preserve your Data (as defined in Section 5.2)
            for continued use with your paid subscription plan. If you do not
            subscribe to a paid subscription plan at or before the end of the
            Trial Period, we reserve the right to delete your Data after the
            expiration of the Trial Period.
          </Paragraph>
          <Paragraph>
            NOTWITHSTANDING SECTION 7 (REPRESENTATIONS, WARRANTIES, EXCLUSIVE
            REMEDIES AND DISCLAIMERS), DURING THE TRIAL PERIOD THE SERVICE IS
            PROVIDED “AS-IS” AND WITHOUT ANY WARRANTY
          </Paragraph>
        </section>

        <section>
          <Heading>2. Use of Services</Heading>
          <Heading>2.1 Account</Heading>
          <Paragraph>
            In order to access and use the Service, you will need to register
            with us and create an account (“<b>Account</b>”). We reserve the
            right to suspend or terminate your Account if any information
            provided during the registration process or thereafter is or becomes
            inaccurate, false or misleading. You are responsible for maintaining
            the confidentiality of your Account, including any tokens we provide
            to you that enable you to send data to the Service (“
            <b>API Tokens</b>”), and of the login and passwords for all users
            whom you have authorized to access your Account (“
            <b>Authorized Users</b>”). You agree to notify Akuity if any API
            Tokens or passwords are lost, stolen, or disclosed to an
            unauthorized third party, or otherwise may have been compromised.
            You are responsible for all activities that occur under your
            Account, including those carried out by any Authorized Users
            associated with your Account. You will promptly notify Akuity of any
            unauthorized use of or access to the Service.
          </Paragraph>

          <Heading>2.2 Rights to Use the Service</Heading>
          <Paragraph>
            Subject to your compliance with this Agreement, we will make the
            Service available to you and your Authorized Users during the
            applicable subscription term or Trial Period. We hereby grant you
            and your Authorized Users a limited, non-exclusive,
            non-transferable, revocable right to access and use the Service
            during the applicable subscription term or Trial Period, solely for
            your own personal or internal business use. Your rights in the
            Service will be limited to those expressly granted in this Section
            2.2. Akuity and its licensors reserve all rights and licenses in and
            to the Service not expressly granted under this Agreement.
          </Paragraph>

          <Heading>2.3 Prohibitions</Heading>
          <Paragraph>
            You will not: (i) sell, resell, license, sublicense, distribute,
            rent or lease the Service, whether for a fee or not; (ii) copy the
            Service in whole or in part; (iii) access the Service in order to
            build a competitive product or service; (iv) reverse engineer any
            part of the Service; (v) use the Service in any unlawful manner, for
            any unlawful purpose, or in any manner inconsistent with this
            Agreement or applicable documentation; (vi) attempt to gain
            unauthorized access to any part of the Service or its related
            systems or networks; (vii) transmit a virus to, overload, flood,
            spam, or paralyze the Service or take any action or inaction which
            interferes with the integrity of the Service; or (viii) encourage,
            authorize, or enable anyone to do any of the foregoing.
          </Paragraph>

          <Heading>2.4 Subscriptions</Heading>
          <Paragraph>
            The Service is purchased as a subscription. Each subscription plan
            is described in the applicable Order (defined in section 4.1).
          </Paragraph>

          <Heading>2.5 Data Security</Heading>
          <Paragraph>
            The security of your Data is very important to us. We take
            commercially reasonable administrative, technical, organizational,
            and physical measures designed to protect the confidentiality,
            security and integrity of your Data and secure it from accidental
            loss, unauthorized access, use, alteration and disclosure. When Data
            is transmitted via the Service, we encrypt this data in transmission
            using SSL or other technologies. Please be aware, however, that no
            method of transmitting information over the Internet or storing
            information is completely secure. Accordingly, we cannot guarantee
            the absolute security of any Data.
          </Paragraph>

          <Heading>2.6 Modifications to Service</Heading>
          <Paragraph>
            We may change the features within the Service and/or update the
            Service from time to time, without prior notice to you.
          </Paragraph>

          <Heading>2.7 Beta Services or Features</Heading>
          <Paragraph>
            From time to time, we may invite you to try our beta services or
            features under development (“<b>Beta Services or Features</b>”) at
            no charge. You may accept or decline any such invitation at your
            sole discretion. Beta Services or Features will be clearly
            designated as beta, pilot, limited release, developer preview,
            non-production, evaluation or by a description of similar import.
            Beta Services or Features are for evaluation purposes only and not
            for production use, are not considered “Service” under this
            Agreement, are not supported, and may be subject to additional
            terms. Unless otherwise stated, any Beta Services or Features trial
            period will expire upon the earlier of six (6) months from the trial
            start date or the date that a version of the Beta Services or
            Features becomes generally available. We may discontinue Beta
            Services or Features at any time at our sole discretion and may
            never make them generally available. We will have no liability for
            any harm or damage arising out of or in connection with a Beta
            Service or Feature.
          </Paragraph>
        </section>

        <section>
          <Heading>3. Third Party Technology and Services</Heading>
          <Paragraph>
            The Service may provide links to certain third party services, which
            are compatible with our Service, or plugins for our Service, or you
            may use third party services and technology with our Service (“
            <b>Third Party Technology</b>”). We do not endorse and are not
            responsible or liable for the products or services provided by such
            third parties and are not responsible for the operation or
            functionality of such Third Party Technology. You are solely
            responsible for your use of any Third Party Technology, including
            compliance with the terms and conditions governing use of such Third
            Party Technology and you enable, use or access them at your own
            risk.
          </Paragraph>

          <Paragraph>
            We may provide you with software governed by an open source license.
            If there are provisions in those open source licenses that expressly
            conflict with this Agreement, the applicable open source license
            terms will apply. Please review our open source licenses at
            <a
              href="https://github.com/akuity"
              target="_blank"
              rel="noreferrer"
            >
              https://github.com/akuity
            </a>
            .
          </Paragraph>

          <Paragraph>
            ANY THIRD PARTY TECHNOLOGY DOWNLOADED BY YOU OR OTHERWISE OBTAINED
            OR USED IN CONNECTION WITH THE SERVICE IS DONE SO AT YOUR OWN
            DISCRETION AND RISK, AND YOU AND YOUR AUTHORIZED USERS WILL BE
            SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND
            CAUSES OF ACTION WITH RESPECT TO SUCH USE, INCLUDING, BUT NOT
            LIMITED TO ANY DAMAGE TO COMPUTER SYSTEMS, INTERNET ACCESS, DOWNLOAD
            OR DISPLAY DEVICE, OR LOSS OF DATA, THAT RESULTS FROM THE DOWNLOAD
            OR USE OF ANY SUCH THIRD PARTY TECHNOLOGY.
          </Paragraph>
        </section>

        <section>
          <Heading>4. Fees and Payment for Services</Heading>

          <Heading>4.1 Fees</Heading>
          <Paragraph>
            By subscribing to the Service, you agree to pay Akuity all fees set
            forth in a customized order form (an “<b>Order Form</b>”) or an
            order receipt from an online purchase (the “<b>Order Receipt</b>”)
            (together, the Order Form and the Order Receipt are referred to as
            the Order (the “<b>Order</b>”). Except as otherwise specified in an
            Order:{' '}
            <b>
              (i) payment obligations are non-cancelable and fees paid are
              non-refundable, and (ii) quantities purchased cannot be decreased
              during the relevant subscription term.
            </b>{' '}
            You agree that you will be responsible for reimbursing Akuity for
            any and all reasonable costs and expenses incurred in collecting any
            past due amounts or service charges, including, but not limited to,
            reasonable attorneys’ fees, chargeback fees or other related
            charges.
          </Paragraph>

          <Heading>4.2 Payment by Payment Card</Heading>
          <Paragraph>
            If you provide credit or debit card (“<b>Payment Card</b>”)
            information to us, you authorize Akuity (i) to charge your Payment
            Card for verification, pre-authorization and payment purposes at the
            beginning of each subscription term and any renewal subscription
            term(s) as set forth in Section 10.2 (Term of Purchased
            Subscriptions), and (ii) to bear any additional charges that your
            bank or other financial service provider may levy on you. Such
            charges shall be made in advance or in accordance with any different
            billing frequency stated in the applicable Order. We reserve the
            right to suspend or cancel your subscription plan if we are not able
            to charge your Payment Card for any payment period. If you have any
            concerns or objections regarding charges, you agree to raise them
            with Akuity first and you agree not to cancel or reject any Payment
            Card charges unless you have made a reasonable attempt at resolving
            the matter directly with Akuity.
          </Paragraph>

          <Heading>4.3 Invoicing</Heading>
          <Paragraph>
            If the Order specifies that payment will be by a method other than a
            Payment Card, we will invoice you in advance and otherwise in
            accordance with the relevant Order. Unless stated otherwise in the
            Order, invoiced charges are due net thirty (30) days from the
            invoice date. You are responsible for providing complete and
            accurate billing and contact information to us and notifying us of
            any changes to such information. Any past due payments shall be
            subject to an service charge equal to 1.0% per month of the amount
            due or the maximum amount allowed by law, whichever is less.
          </Paragraph>

          <Heading>4.4 Changes to Fee Schedule</Heading>
          <Paragraph>
            We reserve the right to change our fee schedule at any time and we
            will notify you in advance of such changes becoming effective.
            Changes to the fee schedule will not apply retroactively and will
            only apply at the conclusion of your subscription term for your
            subscription renewal. If you do not agree with the changes to the
            Akuity fee schedule, then your only recourse is to stop using the
            Service.
          </Paragraph>

          <Heading>4.5 Taxes</Heading>
          <Paragraph>
            All stated fees are exclusive of taxes or duties of any kind. You
            will be responsible for, and will promptly pay, all taxes and duties
            of any kind (including but not limited to sales, use and withholding
            taxes) associated with this Agreement or your use of the Service,
            except for taxes based on Akuity’s net income.
          </Paragraph>

          <Heading>4.6 Future Functionality</Heading>
          <Paragraph>
            You agree that your purchases are not contingent on the delivery of
            any future functionality or features, or dependent on any oral or
            written public comments made by us regarding future functionality or
            features.
          </Paragraph>
        </section>

        <section>
          <Heading>5. Proprietary Rights and Licenses</Heading>

          <Heading>5.1 Reservation of Rights</Heading>
          <Paragraph>
            We and our licensors exclusively own the Service and all copyrights,
            patents, trademarks, and other intellectual property rights therein.
            You may not remove, alter, or obscure any copyright, trademark, or
            other proprietary rights notices appearing on the Service.
          </Paragraph>

          <Heading>5.2 License by You to Host Your Data</Heading>
          <Paragraph>
            We do not claim any ownership rights in any data, files, text,
            graphics, software, works of authorship of any kind, and information
            or other materials that are captured, or that you transmit to,
            upload to, run on, process on, store in, cause to interface with, or
            use, in connection with, your Account on the Service (“<b>Data</b>
            ”). Nothing in this Agreement will be deemed to restrict any rights
            that you may have to use and exploit this Data.
          </Paragraph>

          <Paragraph>
            You hereby grant to Akuity a non-exclusive, worldwide, transferable
            license to use, reproduce and distribute this Data solely in
            connection with use of the Service and our provision of the Service
            to you. You represent and warrant that you or your licensors own all
            right, title and interest in and to this Data and that you have all
            rights in this Data necessary and sufficient to transmit to, upload
            to, run on, process on, store in, cause to interface with, or use,
            in connection with, your Account on the Service, and to grant the
            rights contemplated by this Agreement. You are solely responsible
            for all of this Data, including but not limited to the development,
            operation, maintenance, and use of this Data. We will store your
            Data in accordance with our standard data retention policies and
            procedures.
          </Paragraph>

          <Paragraph>
            In order for the Service to perform the functions for which it was
            designed, the Service will gather and transmit certain technical
            information, Account information, and metadata associated with your
            use of the Service, to our servers, including but not limited to
            application telemetry, IP addresses, IP configurations, stored
            sessions, open ports, account credentials, network metadata, and
            device operating system, status, version, and configuration
            (collectively, “<b>Metadata</b>”). For clarity, Metadata does not
            include your Data. You agree and consent to access, collection,
            transmittal, storage, monitoring, copying, processing, analysis and
            use of the Metadata and your Data by Akuity in order to administer,
            support, develop and improve the Service and Akuity’s other products
            and services, and to monitor compliance with this Agreement.
          </Paragraph>

          <Paragraph>
            You represent and agree that you shall not submit any third party
            personally identifiable information (collectively, “<b>PII</b>”) for
            use with the Service. PII means any information that, alone or in
            combination with other data, can be used to identify, contact, or
            precisely locate an individual person, including, but not limited
            to, an identifier such as a name, an identification number, location
            data or an online identifier, or any data that has heightened
            security requirements imposed by applicable laws.
          </Paragraph>

          <Heading>5.3 Feedback</Heading>
          <Paragraph>
            If you provide Akuity with any suggestions, comments, or other
            feedback regarding the Service (“<b>Feedback</b>”), you acknowledge
            that such Feedback will become the exclusive property of Akuity, and
            we may use (or not use) any such Feedback in any manner and for any
            purpose, without compensation to you and without implying or
            creating any interest on your part in any of our products or
            services that may be based on such Feedback. You hereby irrevocably
            assign to Akuity all right, title, and interest in any Feedback
            provided to Akuity.
          </Paragraph>
        </section>

        <section>
          <Heading>6. Confidentiality</Heading>
          <Paragraph>
            <b>"Confidential Information"</b> means the Service and Your Data;
            any features, and other information relating to the Service
            (including, without limitation, all Feedback); and any business or
            technical information of either party that is disclosed to the other
            party in connection with this Agreement. The obligations in this
            Section will not apply to the extent any information: (i) is or
            becomes generally known to the public through no fault or breach of
            this Agreement by a party; (ii) is rightfully known by a party at
            the time of disclosure without an obligation of confidentiality;
            (iii) is independently developed by a party without access to or use
            of any Confidential Information; or (iv) is rightfully obtained by a
            party from a third party without restriction on use or disclosure.
            Each party will not use or disclose any Confidential Information,
            except as necessary to exercise the rights expressly granted to such
            party in this Agreement. Each party will use all reasonable efforts
            to protect Confidential Information from unauthorized use or
            disclosure, but in no event less than the efforts that such party
            ordinarily uses with respect to its own proprietary information of a
            similar nature and importance. The recipient may disclose
            Confidential Information only to individuals within its organization
            or entity who have a bona fide need to know such Confidential
            Information for the exercise of its rights under this Agreement, and
            such party will ensure that each such individual first executes a
            written agreement (or is otherwise already bound by a written
            agreement) that contains use and nondisclosure restrictions at least
            as protective of the Confidential Information as those set forth in
            this Agreement.
          </Paragraph>
        </section>

        <section>
          <Heading>
            7. Representations, Warranties, Exclusive Remedies and Disclaimers
          </Heading>

          <Heading>7.1 Representations</Heading>
          <Paragraph>
            Each party represents that it has validly entered into this
            Agreement and has the legal power to do so. For the avoidance of
            doubt, if the Service is to be used by or on behalf of a company or
            other organization, the individual accepting this Agreement
            represents and warrants that he or she has the authority to bind
            that company or other organization to this Agreement, and “you,” and
            “your” will refer to that company or other organization
          </Paragraph>

          <Heading>7.2 Our Warranties</Heading>
          <Paragraph>
            We warrant that the Service will perform substantially in accordance
            with the standard user documentation for the Service that we make
            generally available to users (“<b>Documentation</b>”). As your sole
            and exclusive remedy and Akuity’s entire liability for any breach of
            this limited warranty, we will at our option and expense promptly
            correct the Service so that it conforms to this limited warranty or,
            if we are unable to do so after using reasonable efforts, we will,
            in our discretion, allow you to cancel your subscription for a
            refund of the pro-rata amount of the fee you have paid for the
            then-current payment period.
          </Paragraph>

          <Heading>7.3 Disclaimers</Heading>
          <Paragraph>
            We make no warranty that the Service will meet your requirements or
            be available on an uninterrupted, secure, or error-free basis. You
            acknowledge that the provision of the Service depends on necessary
            hardware, software, networks, storage, and other products and
            services provided by third parties which are not controlled by
            Akuity. We therefore will not be liable for any unavailability of
            the Service that is due to the outage or failure to perform of any
            such third party services on which the Service depends. You assume
            sole responsibility and liability for any output and/or results
            obtained from the use of the Service and for conclusions drawn from
            such use. We will have no liability for any claims, losses, or
            damage caused by errors or omissions in any information provided to
            Akuity by you in connection with the Service or any actions taken by
            Akuity at your direction. We will have no liability for any claims,
            losses or damages arising out of or in connection with your Data.
          </Paragraph>
        </section>

        <section>
          <Heading>8. Mutual Indemnification</Heading>

          <Heading>8.1 Indemnification by You</Heading>
          <Paragraph>
            You will indemnify, defend and hold Akuity and its officers,
            directors, employees and agents, harmless from and against any
            claims, disputes, demands, liabilities, damages, losses, and costs
            and expenses, including, without limitation, reasonable legal and
            accounting fees arising out of or in any way connected with (i) your
            access to or use of the Service, including, but not limited to, your
            breach of this Agreement, or (ii) your Data, including but not
            limited to, the transmission and submission of such Data to the
            Service, and infringement or misappropriation of any third party
            proprietary rights by your Data, provided that we: (a) promptly
            notify you in writing of the claim; (b) grant you sole control of
            the defense and settlement of the claim; and (c) provide you, at
            your expense, with all assistance, information and authority
            reasonably required for the defense and settlement of the claim.
          </Paragraph>

          <Heading>8.2 Indemnification by Us</Heading>
          <Paragraph>
            We will indemnify, defend and hold you harmless from and against any
            claims, disputes, demands, liabilities, damages, losses, and costs
            and expenses, including, without limitation, reasonable legal and
            accounting fees attributable to such claim that are awarded against
            you, only to the extent based upon a third party claim that the
            Service, as provided by under this Agreement and used within the
            scope of this Agreement, infringes any U.S. patent or any copyright
            or misappropriates any trade secret, provided that you: (i) promptly
            notify Akuity in writing of the claim; (ii) grant Akuity sole
            control of the defense and settlement of the claim; and (iii)
            provide Akuity, at its expense, with all assistance, information and
            authority reasonably required for the defense and settlement of the
            claim.
          </Paragraph>

          <Heading>8.3 Exclusive Remedy</Heading>
          <Paragraph>
            THE PROVISIONS OF THIS SECTION 8 SET FORTH AKUITY’S SOLE AND
            EXCLUSIVE OBLIGATIONS, AND YOUR SOLE AND EXCLUSIVE REMEDIES, WITH
            RESPECT TO INFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL PROPERTY
            RIGHTS OF ANY KIND.
          </Paragraph>
        </section>

        <section>
          <Heading>9. Limitation of Liability</Heading>

          <Paragraph>
            AKUITY’S TOTAL LIABILITY TO YOU AND YOUR AUTHORIZED USERS FROM ALL
            CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY UNDER THIS
            AGREEMENT WILL BE LIMITED TO THE AMOUNTS PAID TO AKUITY BY YOU FOR
            THE SERVICE DURING THE LAST PAYMENT PERIOD (I.E. YOUR SUBSCRIPTION
            TERM, NOT TO EXCEED 12 MONTHS) IMMEDIATELY PRECEDING THE EVENTS
            GIVING RISE TO THE LIABILITY. WE WILL NOT BE LIABLE TO YOU OR YOUR
            AUTHORIZED USERS FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR PUNITIVE DAMAGES, OR FOR COSTS OF SUBSTITUTE GOODS
            OR SERVICES, OR FOR LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER
            INTANGIBLE LOSSES, ARISING IN ANY WAY OUT OF THIS AGREEMENT OR
            RESULTING FROM ACCESS TO, USE OF, OR INABILITY TO ACCESS OR USE THE
            SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
            NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE BEEN
            INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF AN EXCLUSIVE
            REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
            PURPOSE.
          </Paragraph>
        </section>

        <section>
          <Heading>10. TERM AND TERMINATION</Heading>

          <Heading>10.1 Term of Agreement</Heading>

          <Paragraph>
            This Agreement will remain in force and effect until all trials and
            subscriptions hereunder have expired or have been terminated.
          </Paragraph>

          <Heading>10.2 Term of Purchased Subscription</Heading>

          <Paragraph>
            The term of each subscription shall be as specified in the
            applicable Order, or as selected via the Akuity website or the
            functionality of the Service. Except as otherwise specified in an
            Order, subscriptions will automatically renew for the additional
            periods equal to the expiring subscription term or one year
            (whichever is shorter), unless either party gives the other notice
            of non-renewal at least thirty (30) days before the end of the
            relevant subscription term, or, in the case of monthly
            subscriptions, at any time before the end of the subscription term.
            The subscription tier pricing during any automatic renewal term will
            be the same as that during the immediately prior term unless we have
            given you written notice of a pricing increase at least sixty (60)
            days before the end of that prior term, in which case the pricing
            increase will be effective upon renewal and thereafter.
          </Paragraph>

          <Heading>10.3 Termination by You</Heading>

          <Paragraph>
            You may terminate this Agreement for cause upon 30 days written
            notice to Akuity of a material breach if such breach remains uncured
            at the expiration of such period.
          </Paragraph>

          <Heading>10.4 Suspension or Termination by Akuity</Heading>

          <Paragraph>
            At Akuity’s sole discretion, we may suspend or terminate your
            Account and use of the Service, immediately and without notice, if
            (a) you have breached any of the terms in this Agreement, if (b) you
            have acted in a manner that clearly shows you do not intend to, or
            are unable to, comply with the terms of this Agreement, or (c) you
            have not paid fees due under the Agreement. In addition, we may
            suspend or limit your Account and use of the Service as we deem
            appropriate to prevent, investigate, or otherwise address any
            suspected misuse of the Service.
          </Paragraph>

          <Heading>10.5 Surviving Provisions</Heading>

          <Paragraph>
            The provisions of Sections 2.3 and 3 through 11 will survive any
            expiration or termination of this Agreement. After termination or
            expiration of this Agreement and only upon your written request, for
            paid subscription plans Account holders only, we will return the
            portion of your metrics Data submitted to the Service and stored in
            accordance with our data retention policy and procedures, provided
            however that we are solely responsible for the form, delivery
            mechanism and composition of this Data to be returned to you.
          </Paragraph>
        </section>

        <section>
          <Heading>11. General Provisions</Heading>

          <Heading>11.1 Export Control</Heading>

          <Paragraph>
            You agree to comply fully with all relevant export laws and
            regulations of the United States and other applicable jurisdictions
            to ensure that neither the Service, nor any direct product thereof,
            are: (i) downloaded or otherwise exported or re-exported directly or
            indirectly in violation of such export laws and regulations; or (ii)
            used for any purposes prohibited by the such export laws and
            regulations, including but not limited to nuclear, chemical, or
            biological weapons proliferation.
          </Paragraph>

          <Heading>11.2 U.S. Government End Users</Heading>

          <Paragraph>
            The Service and Documentation are “commercial items” as that term is
            defined in FAR 2.101, consisting of “commercial computer software”
            and “commercial computer software documentation,” respectively, as
            such terms are used in FAR 12.212, DFARS 227.7202 and other
            government acquisition regulations, as applicable. If the Service
            and Documentation are being acquired by or on behalf of the U.S.
            Government, then, as provided in FAR 12.212 and DFARS 227.7202-1
            through 227.7202-4, as applicable, the U.S. Government’s rights in
            the Service and Documentation will be only those specified in this
            Agreement.
          </Paragraph>

          <Heading>11.3 Dispute Resolution</Heading>

          <Paragraph>
            This Agreement and any action related thereto will be governed by
            the laws of the State of California without regard to its conflict
            of laws provisions. You and we irrevocably consent to the
            jurisdiction of, and venue in, the state or federal courts located
            in Santa Clara County, in the State of California for any disputes
            arising under this Agreement.
          </Paragraph>

          <Heading>11.4 Publicity</Heading>

          <Paragraph>
            You agree that we may identify you as a customer of the Service, and
            display your name and logo (if any) in connection with such
            identification, on the Akuity websites and in its other published
            marketing materials. We will use good-faith efforts to comply with
            any reasonable trademark usage guidelines you provide to Akuity in
            connection with your name and logo.
          </Paragraph>

          <Heading>11.5. Assignment</Heading>

          <Paragraph>
            You may not assign any rights or obligations under this Agreement,
            whether by operation of law or otherwise, without our prior written
            consent; provided, however, you may assign this Agreement in its
            entirety, without our consent in connection with a merger,
            acquisition, corporate reorganization, or sale of all or
            substantially all of your assets. Notwithstanding the foregoing, if
            you are acquired by, sell substantially all of your assets to, or
            undergo a change of control in favor of, a direct competitor of
            Akuity, then we may terminate this Agreement upon written notice. In
            the event of such a termination, we will refund to you any prepaid
            fees covering the remainder of the term of all Account
            subscriptions. We may assign this Agreement freely to any affiliate
            or in the event of merger, acquisition, corporate reorganization, or
            sale of all or substantially all of our assets. Subject to the
            foregoing, this Agreement will bind and inure to the benefit of the
            parties, their successors and permitted assigns.
          </Paragraph>

          <Heading>11.6 Notice</Heading>

          <Paragraph>
            We may provide any notice to you under this Agreement by: (i)
            posting a notice on the Akuity website; or (ii) sending a message to
            the administrative email address(es) then associated with your
            Account. Notices we provide by posting on the Akuity website will be
            effective upon posting and notices we provide by email will be
            effective when we send the email. It is your responsibility to keep
            your email address(es) current. You will be deemed to have received
            any email sent to the email address then associated with your
            account when we send the email, whether or not you actually receive
            the email. Notices made by you to Akuity should be sent via email at
            <a href="mailto:legal@akuity.io">legal@akuity.io</a>.
          </Paragraph>

          <Heading>11.7 Severability</Heading>

          <Paragraph>
            In the event that any provision of this Agreement is held to be
            invalid or unenforceable, that provision will be enforced to the
            maximum extent permissible, and the remaining provisions of this
            Agreement will remain in full force and effect.
          </Paragraph>

          <Heading>11.8 Waiver</Heading>

          <Paragraph>
            Akuity’s failure to enforce any right or provision of this Agreement
            will not be considered a waiver of such right or provision. The
            waiver of any such right or provision will be effective only if in
            writing and signed by a duly authorized representative of Akuity.
          </Paragraph>

          <Heading>11.9 No Election of Remedy</Heading>

          <Paragraph>
            Except as expressly set forth in this Agreement, the exercise by the
            parties of any of their remedies under this Agreement will be
            without prejudice to their other remedies under this Agreement or
            otherwise.
          </Paragraph>

          <Heading>11.10 Force Majeure</Heading>

          <Paragraph>
            Neither party will be responsible for any failure or delay in its
            performance under this Agreement (except for the payment of money)
            due to causes beyond its reasonable control, including, but not
            limited to, labor disputes, strikes, lockouts, shortages of or
            inability to obtain labor, energy, raw materials or supplies, war,
            acts of terror, riot, acts of God or governmental action.
          </Paragraph>

          <Heading>11.11 Entire Agreement</Heading>

          <Paragraph>
            This Agreement constitutes the entire agreement between the parties
            regarding the Service, and it supersedes and replaces any prior
            agreements and understandings between the parties regarding the
            Service; provided that, in the event that any provision of this
            Agreement conflicts with the terms of an Order, such conflict or
            inconsistency shall be resolved by giving precedence to the Order.
          </Paragraph>
        </section>
      </div>
    </Layout>
  );
};

const content = {
  seo: seoConfiguration['terms-of-service']
};

export default TermsOfServicePage;
